import { SVGProps, FC } from 'react'

type ClockProps = SVGProps<SVGSVGElement> & {
  color?: string
}

export const Clock: FC<ClockProps> = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00008 1.99984C4.68637 1.99984 2.00008 4.68613 2.00008 7.99984C2.00008 11.3135 4.68637 13.9998 8.00008 13.9998C11.3138 13.9998 14.0001 11.3135 14.0001 7.99984C14.0001 4.68613 11.3138 1.99984 8.00008 1.99984ZM0.666748 7.99984C0.666748 3.94975 3.94999 0.666504 8.00008 0.666504C12.0502 0.666504 15.3334 3.94975 15.3334 7.99984C15.3334 12.0499 12.0502 15.3332 8.00008 15.3332C3.94999 15.3332 0.666748 12.0499 0.666748 7.99984Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00008 3.33317C8.36827 3.33317 8.66675 3.63165 8.66675 3.99984V7.58782L10.9649 8.73689C11.2942 8.90155 11.4277 9.302 11.263 9.63131C11.0984 9.96063 10.6979 10.0941 10.3686 9.92946L7.70194 8.59612C7.47608 8.48319 7.33342 8.25235 7.33342 7.99984V3.99984C7.33342 3.63165 7.63189 3.33317 8.00008 3.33317Z"
      fill="currentColor"
    />
  </svg>
)

export default Clock
