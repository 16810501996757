import { SVGProps, FC } from 'react'

type MapPinProps = SVGProps<SVGSVGElement> & {
  color?: string
}

export const MapPin: FC<MapPinProps> = props => (
  <svg width="12" height="16" viewBox="0 0 12 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00024 1.99984C4.76257 1.99984 3.57558 2.4915 2.70041 3.36667C1.82524 4.24184 1.33358 5.42883 1.33358 6.6665C1.33358 8.40315 2.51389 10.2409 3.83185 11.7236C4.47519 12.4474 5.1206 13.0526 5.60591 13.4773C5.75437 13.6072 5.88739 13.7198 6.00024 13.8133C6.1131 13.7198 6.24612 13.6072 6.39457 13.4773C6.87988 13.0526 7.5253 12.4474 8.16864 11.7236C9.4866 10.2409 10.6669 8.40315 10.6669 6.6665C10.6669 5.42883 10.1752 4.24184 9.30008 3.36667C8.42491 2.4915 7.23792 1.99984 6.00024 1.99984ZM6.00024 14.6665C5.60024 15.1998 5.6001 15.1997 5.59993 15.1996L5.59827 15.1984L5.59436 15.1954L5.58085 15.1851C5.56936 15.1764 5.55296 15.1638 5.53197 15.1475C5.48998 15.115 5.42962 15.0677 5.35348 15.0065C5.20128 14.8842 4.98573 14.7063 4.72791 14.4807C4.21322 14.0304 3.5253 13.3857 2.8353 12.6094C1.4866 11.0921 0.000244141 8.92986 0.000244141 6.6665C0.000244141 5.0752 0.632385 3.54908 1.7576 2.42386C2.88282 1.29865 4.40894 0.666504 6.00024 0.666504C7.59154 0.666504 9.11767 1.29865 10.2429 2.42386C11.3681 3.54908 12.0002 5.0752 12.0002 6.6665C12.0002 8.92986 10.5139 11.0921 9.16518 12.6094C8.47519 13.3857 7.78727 14.0304 7.27258 14.4807C7.01476 14.7063 6.79921 14.8842 6.647 15.0065C6.57087 15.0677 6.5105 15.115 6.46852 15.1475C6.44753 15.1638 6.43112 15.1764 6.41964 15.1851L6.40613 15.1954L6.40222 15.1984L6.40099 15.1993C6.40082 15.1994 6.40024 15.1998 6.00024 14.6665ZM6.00024 14.6665L6.40024 15.1998C6.16321 15.3776 5.83697 15.3774 5.59993 15.1996L6.00024 14.6665Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00024 5.33317C5.26386 5.33317 4.66691 5.93012 4.66691 6.6665C4.66691 7.40288 5.26386 7.99984 6.00024 7.99984C6.73662 7.99984 7.33358 7.40288 7.33358 6.6665C7.33358 5.93012 6.73662 5.33317 6.00024 5.33317ZM3.33358 6.6665C3.33358 5.19374 4.52748 3.99984 6.00024 3.99984C7.473 3.99984 8.66691 5.19374 8.66691 6.6665C8.66691 8.13926 7.473 9.33317 6.00024 9.33317C4.52748 9.33317 3.33358 8.13926 3.33358 6.6665Z"
      fill="currentColor"
    />
  </svg>
)

export default MapPin
