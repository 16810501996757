import { Toaster } from 'react-hot-toast'
import { FC } from 'react'

import { useIsMounted } from '@genie-fintech/ui/hooks'
import { Portal } from '@genie-fintech/ui/components'

import { RouterProvider } from '$contexts/Router'
import useBootstrap from '$actions/useBootstrap'

import { toast } from '$styles/atoms.css'

import Routing from '$app/Routing'

import Sprite from '@genie-fintech/ui/icons/Sprites'
import BrandIconSprites from '$icons/BrandIcon/Sprites'
import EnvIconSprites from '$icons/EnvIcon/Sprites'
import DeviceIconSprites from '$icons/DeviceIcon/Sprites'

const Contents: FC = () => {
  const isMounted = useIsMounted()

  useBootstrap()

  if (!isMounted) return null

  return (
    <>
      <Sprite />
      <BrandIconSprites />
      <EnvIconSprites />
      <DeviceIconSprites />

      <Routing />

      <Portal>
        <Toaster containerClassName={toast} />
      </Portal>
    </>
  )
}

const App: FC = () => (
  <RouterProvider>
    <Contents />
  </RouterProvider>
)

export default App
