import { FC, useMemo } from 'react'
import { format, parseISO } from 'date-fns'

import {
  // Button,
  Spinner
} from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import {
  buildURL,
  clipboardCopyText,
  pluralize,
  valueOrUndefined
} from '$app/utilities'

import { serviceName } from '$constants/texts'

import usePageClass from '$browser/usePageClass'
import useAppList from '$actions/useAppList'
import useSignal from '$actions/useSignal'
import useActiveProfileLogout from '$actions/useActiveProfileLogout'

import AppCard from '$elements/AppCard'
import ContentCard from '$elements/ContentCard'
import { ProfilePhoto } from '$elements/ProfilePhoto'
import ProfileInfo from '$elements/ProfileInfo'
import ChangePasswordButton from '$blocks/ChangePasswordButton'

import lazyToast from '$services/lazyToast'
import { CommonAPI } from '$services/api/common'

import { profile as profileStore } from '$store/profile'

import Details from './Details'

import {
  profilePhotoFigure,
  container,
  logoutButton,
  aside,
  main,
  profileCard,
  accountIdTitle,
  accountIdValue,
  accountId,
  accountIdCopy,
  lastSignIn,
  timeValue,
  profileInfo,
  settingCard,
  changePasswordButton,
  joinedTime,
  joinedTimeTitle,
  joinedTimeValue,
  detailsCard,
  appListCard,
  devicesListCard
} from './styles.css'
import useLoginDevices from '$actions/useLoginDevices'
import LoginDevices from '$elements/LoginDevices'
import { GENERAL_TIME_FORMAT_WITH_TIMEZONE } from '$constants'

const pageName = 'Apps'

const { errorMessageResolver } = new CommonAPI()

const Loader: FC = () => (
  <Spinner
    color="neutral.100"
    style={{
      alignSelf: 'center',
      justifySelf: 'center',
      marginLeft: 'auto',
      marginRight: 'auto'
    }}
  />
)

export const Apps: FC = () => {
  usePageClass({ name: pageName })

  const profile = useSignal(profileStore)
  const { formatted, isEmpty, isLoading } = useAppList()
  const {
    devices,
    isEmpty: isDeviceEmpty,
    isLoading: isDevicesLoading
  } = useLoginDevices()
  const logoutRequest = useActiveProfileLogout()
  const activeProfile = { ...profile }
  const isProfileLoading = !!activeProfile.loading
  const profileData = activeProfile.data
  const profileAlias = profileData?.phone || profileData?.email

  const created_at = profileData?.created_at
  const last_login_at = profileData?.last_login_at
  const id = profileData?.id
  const auth_user = id

  const lastLoginTime = useMemo(() => {
    if (!last_login_at) return

    return format(parseISO(last_login_at), GENERAL_TIME_FORMAT_WITH_TIMEZONE)
  }, [last_login_at])
  const createdTime = useMemo(() => {
    if (!created_at) return

    return format(parseISO(created_at), GENERAL_TIME_FORMAT_WITH_TIMEZONE)
  }, [created_at])

  const withAuthUserQueryParam = useMemo(
    () =>
      formatted.map(({ domain, environment, ...rest }) => ({
        ...rest,
        domain,
        environment: environment?.value,
        href: buildURL(domain, { queryParams: { auth_user } }).toString()
      })),
    [formatted, auth_user]
  )

  const appListCount = formatted.length

  const applistTitle = useMemo(() => {
    if (!isEmpty) {
      return `Access ${appListCount.toLocaleString()} ${pluralize(
        appListCount,
        {
          plural: 'Applications',
          singular: 'Application'
        }
      )} To Use`
    }

    return 'Applications'
  }, [appListCount, isEmpty])

  const applistDescription = useMemo(() => {
    if (isLoading) return

    if (isEmpty) return "You don't have access to any applications."

    return `You have access on these applications to manage on ${serviceName}.`
  }, [isEmpty, isLoading])

  const copyAccountID = () => {
    if (!id) return

    lazyToast(clipboardCopyText(`${id}`), {
      loading: 'Copying..',
      error: errorMessageResolver,
      success: 'Copied!'
    })
  }
  const doLogout = () => {
    lazyToast(logoutRequest.logout(), {
      loading: 'Clearing session..',
      error: errorMessageResolver,
      success: 'Logout success.'
    })
  }

  return (
    <section className={container}>
      <aside className={aside}>
        <ContentCard
          title="Profile Name"
          description={`Your name will be visible to your coworkers on ${serviceName}.`}
          mainClassName={profileCard}
        >
          {isProfileLoading ? (
            <Loader />
          ) : (
            <>
              <ProfilePhoto
                src={profileData?.profile_photo_url}
                className={profilePhotoFigure}
              />

              <ProfileInfo
                photo={profileData?.profile_photo_url}
                name={profileData?.name}
                alias={profileAlias}
                containerProps={{
                  className: profileInfo,
                  'data-testid': 'profile-info'
                }}
                nameProps={{ 'data-testid': 'profile-name' }}
                aliasProps={{ 'data-testid': 'profile-alias' }}
              />

              <div className={accountId}>
                <h4 className={accountIdTitle}>User ID</h4>

                <p
                  className={accountIdValue}
                  data-testid="profile-info-account-id"
                >
                  #{profileData?.id}
                </p>

                <button
                  type="button"
                  className={accountIdCopy}
                  onClick={copyAccountID}
                >
                  <Icon namespace="Copy" />
                </button>
              </div>

              {lastLoginTime && (
                <p className={lastSignIn}>
                  Last Signed In{' '}
                  <span className={timeValue}>{lastLoginTime}</span>
                </p>
              )}
            </>
          )}
        </ContentCard>

        <ContentCard
          title="Setting"
          description={`Your setting can be editable on ${serviceName}.`}
          containerProps={{ style: { gap: 24 } }}
          mainClassName={settingCard}
        >
          {isProfileLoading ? (
            <Loader />
          ) : (
            <>
              <ChangePasswordButton className={changePasswordButton}>
                <Icon namespace="Lock" />
                Change password
              </ChangePasswordButton>

              <button
                type="button"
                title="Logout"
                name="logout"
                className={logoutButton}
                disabled={logoutRequest.loading}
                onClick={doLogout}
              >
                <Icon namespace="LogOut" />
                logout
              </button>

              {createdTime && (
                <div className={joinedTime}>
                  <h4 className={joinedTimeTitle}>Joined ON</h4>
                  <p className={joinedTimeValue}>{createdTime}</p>
                </div>
              )}
            </>
          )}
        </ContentCard>
      </aside>

      <main className={main}>
        <ContentCard
          title="Personal Details"
          description={`Your personal details can be editable on ${serviceName}.`}
          // nav={
          //   <Button
          //     styleVariants={{
          //       type: 'outlined',
          //       kind: 'neutral',
          //       size: 'small'
          //     }}
          //   >
          //     <Icon namespace="EditPencil" />
          //     Edit
          //   </Button>
          // }
          containerProps={{ style: { gap: 24 } }}
          mainClassName={detailsCard}
          mainProps={{ 'data-loading': trueOrUndefined(isProfileLoading) }}
        >
          {isProfileLoading ? (
            <Loader />
          ) : (
            <>
              <Details title="User Name" data-testid="profile-info-name">
                {profileData?.name}
              </Details>

              <Details title="Email Address" data-testid="profile-info-email">
                {profileData?.email}
              </Details>

              <Details title="Phone Number" data-testid="profile-info-phone">
                {profileData?.phone}
              </Details>

              <Details title="Address" data-testid="profile-info-address">
                {profileData?.address}
              </Details>
            </>
          )}
        </ContentCard>

        <ContentCard
          title={applistTitle}
          description={applistDescription}
          containerProps={{
            'data-testid': valueOrUndefined(isEmpty, 'no-apps'),
            style: { gap: 20 }
          }}
          mainClassName={appListCard}
          mainProps={{ 'data-loading': trueOrUndefined(isLoading) }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            withAuthUserQueryParam.map(
              ({
                id,
                name,
                domain,
                environment,
                href,
                description,
                logo,
                brand
              }) => (
                <AppCard
                  key={id}
                  href={href}
                  name={name}
                  description={description}
                  logo={logo}
                  brand={brand}
                  environment={environment}
                  aProps={{
                    'data-testid': `app-name:${name} app-id:${id} app-domain:${domain}`
                  }}
                />
              )
            )
          )}
        </ContentCard>

        <ContentCard
          title="MANAGE ALL DEVICES"
          description="Manage and review user's login devices for efficient system administration."
          containerProps={{
            'data-testid': valueOrUndefined(isDeviceEmpty, 'no-devices'),
            style: { gap: 20 }
          }}
          mainClassName={devicesListCard}
        >
          {isDevicesLoading ? <Loader /> : <LoginDevices devices={devices} />}
        </ContentCard>
      </main>
    </section>
  )
}

export default Apps
